import React from "react";
import PortfolioContainer from "./components/PortfolioContainer";
import "./style.css";

function App() {
    return (
        <PortfolioContainer />
    );
  }

export default App;
